import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const AboutPage = () => {
  return (
    <Layout pageTitle="Yhteys">
      <p>Toimistomme sijaitsee Helsingin keskustassa osoitteessa Simonkatu 12 B.</p>

      <StaticImage
        alt="Simonkatu 12B, 00100 Helsinki"
        src="../images/kartta.png"
      />

    </Layout>
  )
}
export default AboutPage